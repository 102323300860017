<template>
  <div>
    <v-container fluid class="pb-0">
      <v-row>
        <v-toolbar
          flat
          :height="$vuetify.breakpoint.smAndDown ? '120px' : '100px'"
        >
          <v-toolbar-title
            @click.stop="drawer = !drawer"
            style="cursor: pointer"
            ><v-app-bar-nav-icon></v-app-bar-nav-icon> Menu</v-toolbar-title
          >

          <v-spacer></v-spacer>
          <router-link to="/"
            ><img src="@/assets/images/logo.png" width="120"
          /></router-link>
          <v-spacer
            :style="$vuetify.breakpoint.smAndDown ? 'display:none' : ''"
          ></v-spacer>
          <v-toolbar-item
            class="mr-lg-5"
            :style="$vuetify.breakpoint.smAndDown ? 'display:none' : ''"
          >
            <v-btn flat to="/" plain>
              <v-icon>mdi-home</v-icon>
            </v-btn>

            <v-menu
              :rounded="rounded"
              open-on-hover
              offset-y
              transition="scale-transition"
              left
              bottom
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn flat v-bind="attrs" v-on="on" plain to="/units-concerns">
                  Units & Concerns
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click.stop="activeOverM = !activeOverM">
                  <v-list-item-action>
                    <v-list-item-title
                      ><v-icon>mdi-chevron-down</v-icon>Best Holdings
                      Limited</v-list-item-title
                    >
                  </v-list-item-action>
                </v-list-item>
                <v-list-item
                  router
                  :to="item.link"
                  v-show="activeOverM"
                  class="pl-lg-15"
                  v-for="(item, i) in subMenuM"
                  :key="i"
                >
                  <v-list-item-action>
                    <v-list-item-title>{{ item.name }}</v-list-item-title>
                  </v-list-item-action>
                </v-list-item>
                <v-list-item @click.stop="activeOverM1 = !activeOverM1">
                  <v-list-item-action>
                    <v-list-item-title
                      ><v-icon>mdi-chevron-down</v-icon>Best Hotels
                      Limited</v-list-item-title
                    >
                  </v-list-item-action>
                </v-list-item>
                <v-list-item
                  router
                  to="/marriott"
                  v-show="activeOverM1"
                  class="pl-lg-13"
                >
                  <v-list-item-action>
                    <v-list-item-title>Marriott </v-list-item-title>
                  </v-list-item-action>
                </v-list-item>
                <v-list-item router to="/dhamshur-economic-zone" class="pl-8">
                  <v-list-item-action>
                    <v-list-item-title
                      >Dhamshur Economic Zone
                    </v-list-item-title>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-btn to="/csr" flat plain> CSR </v-btn>
            <v-btn to="/contact" flat plain> Contact </v-btn>
          </v-toolbar-item>
        </v-toolbar>
      </v-row>
    </v-container>
    <v-navigation-drawer
      class="side-drawer"
      v-model="drawer"
      fixed
      left
      temporary
      hide-overlay
      width="300px"
    >
      <v-list nav>
        <v-list-item class="logo">
          <v-list-item-title
            ><img src="@/assets/images/logo.png" alt="logo" width="100px"
          /></v-list-item-title>
        </v-list-item>
        <v-list-item @mouseover="mouseOver1" :class="activeOver1 ? 'yes' : ''">
          <v-list-item-title>About Us</v-list-item-title>
        </v-list-item>
        <v-list-item @mouseover="mouseOver2" :class="activeOver2 ? 'yes' : ''">
          <v-list-item-title>Investor Info</v-list-item-title>
        </v-list-item>
        <v-list-item
          link
          to="/units-concerns"
          @mouseover="mouseOver3"
          :class="activeOver3 ? 'yes' : ''"
        >
          <v-list-item-title> Units & Concerns</v-list-item-title>
        </v-list-item>
        <v-list-item link to="/csr" @mouseover="mouseOver4">
          <v-list-item-title>CSR</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-navigation-drawer
      class="drawer-side"
      v-model="drawer"
      width="500px"
      fixed
      left
      temporary
    >
      <v-list nav>
        <v-list-item class="close">
          <v-spacer></v-spacer>
          <v-list-item-icon @click.stop="drawer = !drawer">
            <v-icon color="#fff">mdi-close</v-icon>
          </v-list-item-icon>
        </v-list-item>
        <v-list-item
          v-show="activeOver1"
          v-for="(item, index) in sideMenu"
          :key="index"
          router
          :to="item.link"
        >
          <v-list-item-action @mouseover="() => aboutSubMenuMouseOver(item.id)">
            <v-list-item-title>{{ item.name }}</v-list-item-title>
          </v-list-item-action>
        </v-list-item>
        <v-list-item
          @mouseover="mouseOverIpo"
          v-show="activeOver2"
          router
          to="/prospectus"
        >
          <v-list-item-action>
            <v-list-item-title>IPO Information</v-list-item-title>
          </v-list-item-action>
        </v-list-item>
        <v-list-item
          v-show="activeSubOverInvestor"
          v-for="(item, index) in sideMenuInvestor"
          :key="index"
          router
          :to="item.link"
        >
          <v-list-item-action class="ml-4">
            <v-list-item-title>{{ item.name }}</v-list-item-title>
          </v-list-item-action>
        </v-list-item>
        <v-list-item
          v-show="activeOver2"
          @mouseover="outSide"
          v-for="(item, index) in sideMenu1"
          :key="index"
          router
          :to="item.link"
        >
          <v-list-item-action>
            <v-list-item-title>{{ item.name }}</v-list-item-title>
          </v-list-item-action>
        </v-list-item>
        <v-list-item v-show="activeOver3" @mouseover="mouseOverSide">
          <v-list-item-action>
            <v-list-item-title> Best Holdings Limited </v-list-item-title>
          </v-list-item-action>
        </v-list-item>
        <v-list-item
          router
          :to="item.link"
          v-show="activeSubOver"
          v-for="(item, i) in subMenuM"
          :key="i"
        >
          <v-list-item-action class="ml-4">
            <v-list-item-title>{{ item.name }}</v-list-item-title>
          </v-list-item-action>
        </v-list-item>
        <v-list-item v-show="activeOver3" @mouseover="mouseOverSide1">
          <v-list-item-action>
            <v-list-item-title> Best Hotels Limited </v-list-item-title>
          </v-list-item-action>
        </v-list-item>
        <v-list-item
          v-show="aboutSubMenuOver"
          router
          :to="item.link"
          v-for="item in aboutSubMenu"
          :key="item.id"
        >
          <v-list-item-action>
            <v-list-item-title> {{ item.name }}</v-list-item-title>
          </v-list-item-action>
        </v-list-item>
        <v-list-item router to="/marriott" v-show="activeSubOver1">
          <v-list-item-action class="ml-4">
            <v-list-item-title>Marriott</v-list-item-title>
          </v-list-item-action>
        </v-list-item>
        <v-list-item v-show="activeOver3" router to="/dhamshur-economic-zone">
          <v-list-item-action>
            <v-list-item-title> Dhamshur Economic Zone </v-list-item-title>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>
<script>
export default {
  data() {
    return {
      drawer: null,
      activeOver1: false,
      activeOver2: false,
      activeOver3: false,
      activeOverM: false,
      activeOverM1: false,
      activeSubOver: false,
      activeSubOver1: false,
      aboutSubMenuOver: false,
      activeSubOverInvestor: false,
      //  subMenu:[
      //   {name:'Le Meridian commercial complex'},
      //   {name:'Le Meridian commercial complex'},
      //   {name:'Le Meridian commercial complex'},
      //   {name:'Le Meridian commercial complex'},
      //   {name:'Le Meridian commercial complex'},
      //   {name:'Le Meridian commercial complex'},
      //  ],
      sideMenu: [
        // {name:"About Company",link:"/about-company"},
        { id: 1, name: "Our Vision", link: "/vision" },
        { id: 2, name: "Our Mission", link: "/mission" },
        { id: 3, name: "Board of Directors", link: "/board-directors" },
        { id: 4, name: "Management Team", link: "/management-team" },
        { id: 5, name: "Corporate Governance", link: "/corporate-governance" },
      ],
      sideMenu1: [
        { name: "Notice", link: "/notice" },
        { name: "Proxy Form", link: "/proxy-form" },
        { name: "Directors Profile", link: "" },
        { name: "Quarterly Reports", link: "/quarterly-reports" },
        { name: "Half Yearly Reports", link: "" },
        { name: "Utilization Reports", link: "/utilization-reports" },
        { name: "Annual Reports", link: "/annual" },
        { name: "Price Sensitive Details", link: "/price-sensitive" },
        { name: "Dividend", link: "" },
        { name: "AGM/EGM", link: "" },
        { name: "Investors relation", link: "" },
        { name: "Financial Statement", link: "/financial-statement" },
        { name: "Shareholding Position", link: "shareholding-position" },
      ],
      sideMenuInvestor: [{ name: "Prospectus", link: "/prospectus" }],
      subMenuM: [
        { name: "Le Meridien Dhaka", link: "/le-meridien-dhaka" },
        // {name:'Le Meridian commercial complex',link:'/le-meridian-commercial-complex'},
        {
          name: "Le Meridian service suite and executive apartment",
          link: "/le-meridian-service",
        },
        {
          name: "The Muslin A Luxury  Collection",
          link: "/he-muslin-a-luxury",
        },
        { name: "Luxury villa", link: "/luxury-villa" },
        // {name:'Healthcare Franchise with Commercial Complex, Birulia-Dhaka',link:'/healthcare-franchise'}
      ],
      aboutSubMenu: [
        {
          name: "Corporate Governance Complince",
          link: "/corporate-governance-complince",
        },
        {
          name: "Corporate Governance Statement",
          link: "/corporate-governance-statement",
        },
        {
          name: "Code Of Conduct",
          link: "/code-conduct",
        },
      ],
      icons: [
        { name: "mdi-facebook", color: "#4267B2" },
        { name: "mdi-twitter", color: "#1DA1F2" },
        { name: "mdi-youtube", color: "#FF0000" },
      ],
    };
  },
  methods: {
    mouseOver1: function () {
      this.activeOver1 = true;
      this.activeOver2 = false;
      this.activeOver3 = false;
      this.activeSubOver1 = false;
      this.activeSubOver = false;
      this.activeSubOverInvestor = false;
      this.aboutSubMenuOver = false;
    },
    mouseOver2: function () {
      this.activeOver1 = false;
      this.activeOver2 = true;
      this.activeOver3 = false;
      this.activeSubOver1 = false;
      this.activeSubOver = false;
      this.activeSubOverInvestor = false;
      this.aboutSubMenuOver = false;
    },
    mouseOver3: function () {
      this.activeOver1 = false;
      this.activeOver2 = false;
      this.activeOver3 = true;
      this.activeSubOver1 = false;
      this.activeSubOver = false;
      this.activeSubOverInvestor = false;
      this.aboutSubMenuOver = false;
    },
    mouseOver4: function () {
      this.activeOver1 = false;
      this.activeOver2 = false;
      this.activeOver3 = false;
      this.activeSubOver1 = false;
      this.activeSubOver = false;
      this.activeSubOverInvestor = false;
      this.aboutSubMenuOver = false;
    },
    mouseOverM: function () {
      this.activeOverM = true;
      this.activeSubOver1 = false;
      this.activeSubOver = false;
      this.activeSubOverInvestor = false;
      this.aboutSubMenuOver = false;
    },
    mouseOverSide: function () {
      this.activeSubOver = true;
      this.activeSubOver1 = false;
      this.activeSubOverInvestor = false;
      this.aboutSubMenuOver = false;
    },
    mouseOverSide1: function () {
      this.activeSubOver = false;
      this.activeSubOver1 = true;
      this.activeSubOverInvestor = false;
      this.aboutSubMenuOver = false;
    },
    mouseOverIpo: function () {
      this.activeSubOverInvestor = true;
    },
    outSide: function () {
      this.activeSubOverInvestor = false;
    },
    aboutSubMenuMouseOver(id) {
      if (id == 5) {
        this.aboutSubMenuOver = true;
      }
    },
  },
};
</script>
<style lang="scss">
.v-application--is-ltr .v-list-group--sub-group .v-list-group__header {
  padding-left: 5px !important;
}
/* .v-overlay--active{
.v-overlay__scrim{
  opacity: 0.95 !important;
}} */
/* .v-menu__content{
  left: 1380px !important;
} */
.side-drawer.v-navigation-drawer {
  z-index: 99999;
  background-color: rgb(24, 24, 24, 0.6) !important;
  box-shadow: none !important;
  .yes {
    background-color: rgb(237, 28, 36);
  }
  .v-list-item {
    cursor: pointer;
    color: #fff !important;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
  }
  .v-list-item.logo {
    background-color: transparent !important;
  }
  .v-list-item:hover {
    background-color: rgb(237, 28, 36);
    color: #fff !important;
  }
  .v-list-item--active {
    color: #fff !important;
    background-color: rgb(237, 28, 36);
  }
}
.drawer-side.v-navigation-drawer {
  left: 298px !important;
  top: 0;
  background-color: rgb(24, 24, 24, 0.6) !important;
  box-shadow: none !important;
  z-index: 99999;
  .v-list-item {
    cursor: pointer;
    color: #fff !important;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
  }
  .theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
    color: #fff;
  }
  .close {
    background-color: transparent !important;
    cursor: pointer;
    padding-bottom: 25px;
  }
  .v-list-item:hover {
    background-color: rgb(237, 28, 36);
    text-align: center;
  }
  .v-list-item--active {
    color: #fff !important;
    background-color: rgb(237, 28, 36);
  }
}
@media only screen and (min-width: 768px) {
  .v-toolbar__content,
  .v-toolbar__extension {
    padding: 4px 0px !important;
  }
  .side-drawer.v-navigation-drawer {
    width: 250px !important;
    .v-list-item {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      text-align: left !important;
    }
  }
  .drawer-side.v-navigation-drawer {
    width: 450px !important;
    left: 248px !important;
    top: 0;
    .v-list-item {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      text-align: left !important;
    }
  }
}
@media only screen and (max-width: 600px) {
  .v-toolbar__content,
  .v-toolbar__extension {
    padding: 4px 0px !important;
  }
  .side-drawer.v-navigation-drawer {
    width: 180px !important;
    .v-list-item {
      font-weight: 400;
      font-size: 12px;
      line-height: 24px;
      text-align: left !important;
    }
  }
  .drawer-side.v-navigation-drawer {
    width: 250px !important;
    left: 178px !important;
    top: 0;
    .v-list-item {
      font-weight: 400;
      font-size: 12px;
      line-height: 24px;
      text-align: left !important;
    }
  }
}
@media only screen and (max-width: 390px) {
  .side-drawer.v-navigation-drawer {
    width: 180px !important;
    .v-list-item {
      font-weight: 600;
      font-size: 12px;
      line-height: 24px;
      text-align: left !important;
    }
  }
  .drawer-side.v-navigation-drawer {
    width: 200px !important;
    left: 178px !important;
    top: 0;
    .v-list-item {
      font-weight: 600;
      font-size: 10px;
      line-height: 24px;
      text-align: left !important;
    }
  }
}
@media only screen and (max-width: 320px) {
  .side-drawer.v-navigation-drawer {
    width: 150px !important;
    box-sizing: border-box !important;
    .v-list-item {
      box-sizing: border-box !important;
      font-weight: 400;
      font-size: 10px;
      line-height: 24px;
      text-align: left !important;
    }
  }
  .drawer-side.v-navigation-drawer {
    width: 170px !important;
    left: 45% !important;
    top: 0;
    display: block !important;
    box-sizing: border-box !important;
    .v-list-item {
      box-sizing: border-box !important;
      font-weight: 400;
      font-size: 10px;
      line-height: 24px;
      text-align: left !important;
    }
  }
}
</style>
